import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (!_ctx.hidePasteField)
      ? (_openBlock(), _createBlock(_component_InputText, {
          key: 0,
          modelValue: _ctx.inputData,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputData) = $event)),
          onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.dataChanged($event.data))),
          ref: "inputData"
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.hidePasteField)
      ? (_openBlock(), _createBlock(_component_Button, {
          key: 1,
          label: "Adressdaten löschen und neu einfügen",
          class: "p-button-secondary",
          onClick: _cache[2] || (_cache[2] = ($event: any) => (this.resetData()))
        }))
      : _createCommentVNode("", true)
  ], 64))
}