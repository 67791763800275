
import { GreetingCardService } from "@/classes/GreetingCardService";
import { defineComponent } from "vue";
import { greetingCardService } from "../Grußkarten.vue";
import router from "@/router";
import ClipboardJS from "clipboard";

export default defineComponent({
  name: "Step2",
  data() {
    return {
      greetingCardService: greetingCardService as GreetingCardService,
    };
  },
  mounted: function () {
    if (!greetingCardService.cardType) {
      router.push("1");
    }
    new ClipboardJS(".clipboard-btn");
  },
  components: {},
});
