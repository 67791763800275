
import { GreetingCardService } from "@/classes/GreetingCardService";
import router from "@/router";
import { defineComponent } from "vue";
import { greetingCardService } from "../Grußkarten.vue";

export default defineComponent({
  name: "Step4",
  data() {
    return {
      greetingCardService: greetingCardService as GreetingCardService,
    };
  },
  mounted: function () {
    if (!greetingCardService.cardType) {
      router.push("1");
    }
  },
  components: {},
  methods: {
    generatePDF: async function () {
      await greetingCardService
        .createPdf(this.greetingCardService.addressData)
        .then((status) => {
          console.log(status);
          this.$toast.add({
            severity: "success",
            summary: "Success Message",
            detail: "Order submitted",
            life: 3000,
          });
        });
    },
  },
});
