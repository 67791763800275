
import { GreetingCardService } from "@/classes/GreetingCardService";
import router from "@/router";
import { defineComponent } from "vue";
import { greetingCardService } from "../Grußkarten.vue";

export default defineComponent({
  name: "Step3",
  data() {
    return {
      greetingCardService: greetingCardService as GreetingCardService,
      greetingCardText: "init",
    };
  },
  mounted: function () {
    if (!greetingCardService.cardType) {
      router.push("1");
    }
  },
  components: {},
});
