
import { defineComponent } from "vue";
import { greetingCardService } from "@/views/Grußkarten.vue";
import { GreetingCardService } from "@/classes/GreetingCardService";
import router from "@/router";

export default defineComponent({
  name: "Step1",
  data() {
    return {
      greetingCardService: greetingCardService as GreetingCardService,
    };
  },
  methods: {
    nextStep: function (cardType: "Geburtstagskarte" | "Taufjubiläumskarte") {
      this.$toast.add({
        severity: "success",
        summary: "Success Message",
        detail: "Order submitted",
        life: 3000,
      });
      this.greetingCardService.setGreetingCardType(cardType);
      router.push("2");
    },
  },
  components: {},
});
