
import { defineComponent } from "vue";

export default defineComponent({
  name: "Navigation",
  data() {
    return {
      items: [
        {
          label: "Grußkarten",
          icon: "pi pi-fw pi-file",
          to: "/grusskarten/1",
        },
      ],
    };
  },
  components: {},
});
